.logo {
	text-align: center;
	margin-bottom: 30px;
	max-width: 180px;
}

.logoHolder {
	cursor: pointer;
	width: 180px;
	height: 54px;
	z-index: 2;
}

@media (max-width: 575px) {
	.logoHolder {
		width: 64px;
		height: 20px;
	}
}
