.searchBarMobBox {
	display: none;
}

@media (max-width: 576px) {
	.searchBarMobBox {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 240px;
		min-width: 240px;
		width: 100%;
		padding: 8px 10px 8px 10px;
		height: 36px;
		margin: 0 0.5rem 0 0.5rem;
		background-color: #efefef;
		border-radius: 2px;
	}

	.searchBarMobBoxInput {
		width: 100%;
		height: 100%;
		background-color: #efefef;

		font-weight: 400;
		font-size: 14px;
		line-height: 16.41px;
		color: #404040;
	}

	.iconSearch {
		margin-right: 0.5rem;
	}

	.searchBarMobBoxInput::placeholder {
		font-size: 14px;
		line-height: 16.41px;
		color: #808080;
	}

	.searchBarMobBoxInput:focus {
		font-weight: 400;
		font-size: 14px;
		line-height: 16.41px;
		color: #404040;
	}

	.searchBarMobBoxFilter {
		position: relative;
		display: flex;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		height: 18px;
		border-radius: 2px;
		background-color: #4b9ad4;
		padding: 4px;
		width: 65px;
		transition: all 0.3s;

		&__iconBox {
			top: -10.5px;
			left: 0;
			transition: all 0.3s;
		}

		&__iconBoxActive {
			top: 8px;
			left: 0;
			transition: all 0.3s;
		}

		&__iconCLose {
			transform: rotate(45deg);
		}

		&__text {
			font-weight: 400;
			font-size: 12px;
			line-height: 10px;
			color: #ffffff;
			margin-left: 5px;
			transition: all 0.3s;
		}

		&__textActive {
			display: none;
			transition: all 0.3s;
		}
	}

	.searchBarMobBoxFilterActive {
		transition: all 0.3s;
		width: 18px;
		padding: 4px 0 4px 2px;
	}
}

@media (max-width: 377px) {
	.searchBarMobBox {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
}
