$yellow: #fcb53b;
$white: #fff;

$buttonWidth: 26px;
$buttonHeight: 15px;
$buttonColor: #000000;
$lineThickness: 2px;
$transitionSpeed: 0.25s;
$transitionEasing: ease-in-out;

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 90px;

	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);

	z-index: 130;

	width: 100%;
	max-width: 1920px;
	margin: 0 auto;

	background-color: transparent;
	transition: all 0.5s;
}

@media (max-width: 1442px) {
	.header {
		padding: 1rem 90px;
	}
}

@media (max-width: 1300px) {
	.header {
		padding: 1rem;
	}
}

.togglePanelButton {
	& .searchIcon path {
		fill: #1c1c1c;
		transition: all 0.1s;
	}
}

.togglePanelButton {
	& .isScroolsearchIcon path {
		fill: #fcb53b;
	}
}

.burgerButton {
	display: none;
}

@media (min-width: 577px) {
	.togglePanelButton {
		display: none;
	}
}

@media (max-width: 996px) {
	.navMenuWrapperRight {
		display: flex;
		align-items: center;
	}

	.navMenuWrapper {
		display: none;
	}

	.burgerButton {
		margin-left: 1rem;
		display: block;
		font-size: 1.375rem;
		color: $yellow;
		z-index: 1;
	}

	.homePageHeader {
		.menuToggle {
			color: $yellow !important;
		}
	}
}

@media (max-width: 576px) {
	.header {
		position: sticky;
		display: flex;
		top: 0;
		left: 0;
		transform: translate(0, 0);
		z-index: 151;
		padding: 0;
		justify-content: space-between;

		background-color: #fff;
		padding: 12px 15.5px 12px 15.5px;
		height: 60px;
	}

	.burgerButton {
		margin-left: 0;
		display: block;
		font-size: 1.375rem;
		color: $yellow;
	}

	.navMenuWrapper,
	.viewInventory {
		display: none;
	}
}

.menuToggle {
	position: relative;
	display: block;
	width: $buttonWidth;
	height: $buttonHeight;

	background: transparent;
	border-top: $lineThickness solid;
	border-bottom: $lineThickness solid;

	color: #000000;
	font-size: 0;

	transition: all $transitionSpeed $transitionEasing;

	&:before,
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: $lineThickness;

		position: absolute;
		top: 50%;
		left: 50%;

		background: currentColor;

		transform: translate(-50%, -50%);
		transition: transform $transitionSpeed $transitionEasing;
	}
}

button.isActive {
	border-color: transparent;

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

.logo {
	position: relative;

	&>p {
		position: absolute;
		top: 10px;
		left: -2px;
		font-weight: 500;
		letter-spacing: 2.5px;
		color: #a3a3a3;
		font-family: Verdana, Geneva, Tahoma, sans-serif;
	}
}

.breadcrumbs {
	position: absolute;
	top: 3.2rem;
	left: -4px;
	width: max-content;
}

@media (max-width: 700px) {
	.breadcrumbs {
		display: none;
		position: absolute;
		top: 5rem;
		left: 0;
		width: 100%;
	}
}

.headerSkeleton {
	display: none;
	width: 100%;
	max-width: 240px;
}

.headerSkeletonNoactive {
	border: 1px solid green;
	display: inline-block;
}

.headerSkeletonActive {
	border: 1px solid yellow;
}

.headerBox {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);

	z-index: 1;

	width: 100%;
	max-width: 1920px;
	margin: 0 auto;

	background-color: transparent;
	transition: all 0.5s;
	height: 0rem;
	transition: all 0.3s;
}

.headerBoxActive {
	height: 5rem;
	background-color: white;
	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
	transition: all 0.3s;
}

.homePageHeader {
	.menuToggle {
		color: white;
	}
}

@media (max-width: 576px) {
	.headerBox {
		display: none;
	}

	.homePageHeader {
		.menuToggle {
			color: #000000;
		}
	}
}

@media (max-width: 577px) {
	.headerBox {
		display: none;
	}
}

.headerBackGroundColor {
	background-color: white;
	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

@media (max-width: 377px) {
	.header {
		height: 106px;
		align-items: flex-start;
	}

	.mobFilterBox {
		position: absolute;
		width: 100%;
		max-width: 92%;
		bottom: 10px;
		left: 13px;
	}
}